$primary: #1a1a1a;
$font-family: 'Kufam', cursive;

//@import "~@progress/kendo-theme-material/dist/all.scss";
@import "~@progress/kendo-theme-material/scss/appbar";
@import "~@progress/kendo-theme-material/scss/card";
@import "~@progress/kendo-theme-material/scss/listview";
@import "~@progress/kendo-theme-material/scss/button";

#card-title {
  cursor: pointer;
}

//.Logo {
//  height: 100%;
//  width: 180px;
//  margin: -20px 0 0 0;
//  cursor: pointer;
//}

.language-shell {
  padding: 0 !important;
}

code.language-java {
  padding: 0 !important;
}

.root {
  padding: 0;
}

body {
  background-color: #202020;
}

.title {
  //font-size: 18px;
  margin: 0;
}

.top-title {
  font-size: 1.6rem;
  color: white;
  color: var(--ah-color-white);
  font-weight: bold;
  padding-left: 10px;
  cursor: pointer;
}

.social-section .k-icon {
  margin: 0 4px;
}

a {
  color: #ff0e1a;
  font-size: 1.0rem;
}

ul {
  list-style-type: circle;
  padding: 0 5px 0;
  margin: 0;
  display: flex;
}

li {
  margin: 0 12px;
}

.postDetail li {
  //font-size: 1.0rem;
}

.pageContent {
  /*height: calc(99vh - 95px);*/
  /*height: calc(99vh);*/
}

.k-button {
  padding: 0;
}

blockquote {
  background-color: rgb(29, 31, 33);
  margin: 0 40px;
}

h1 {
  margin-block: unset;
  margin-inline: unset;
  display: unset;
  font-size: 1.6rem;
}

h2 {
  font-size: 1.4rem;
}

h3 {
  font-size: 1.2rem;
}

h4 {
  font-size: 1.0rem;
}

* {
  --ah-color-black: #000000;
  --ah-color-white: white;
}

.appbarTopGitInfo {
  height: 20px;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--ah-color-black) !important;
  align-items: center;
  padding: 0;
  z-index: 90;
  justify-content: end;
}
.appbarTop {
  height: 40px;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--ah-color-black) !important;
  align-items: center;
  padding: 0;
  z-index: 90;
}

.k-author {
  margin: 2px;
  //font-size: medium;
  line-height: normal;
  color: var(--ah-color-white);
}

.contentSummary {
  //font-size: 1.0rem;
  margin-top: 10px;
  color: var(--ah-color-white)
}

.contentTopPosts {
  display: block;
  //font-size: 1.0rem;
  margin-top: 10px;
  color: white;
}

.k-listview.k-widget.k-d-flex.postdetail-listview {
  background-color: white;
}

.k-listview {
  background-color: transparent;
  border-style: none;
  width: 100%;
}

.topPosts .k-card-body {
  height: calc(100vh - 153px);
}

.k-listview-content {
  background-color: var(--ah-color-black);
}

.k-listview-header {
  //font-size: 1.6rem;
  font-weight: bold;
  color: var(--ah-color-white);
  background-color: var(--ah-color-black);
  padding: 0px 10px;
  border-width: 0;
  border-style: solid;
  border-color: inherit;
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
  flex-basis: auto;
  grid-gap: 8px;
  gap: 8px;
}

.k-listview-header .title {
  float: left;
  width: 100%;
  font-size: 1.1rem;
}

.k-listview-header .topPosts {
  float: right;
  width: 30%;
  direction: rtl;
  //font-size: 1.0rem;
  align-self: center;
}

.k-listview-header .topPosts a {
  color: white;
}

pre {
  margin: 0.5rem 0.9rem !important;
}

.postDetail-actions {
  background-color: var(--ah-color-black);
}

.postDetail {
  background-color: var(--ah-color-black);
  color: var(--ah-color-white);
  padding: 0 10px;
}

.postDetail ul {
  display: flow-root;
}

.postDetail-issued {
  background-color: var(--ah-color-black);
  padding: 0 10px;
  //font-size: 1.0rem;
  color: var(--ah-color-white);
}

.postDetail-summary {
  background-color: white;
  padding: 0.1rem 0.5rem;
  //font-size: 1.4rem;
}

.postDetail a {
  color: #e30c17;
  //font-size: 0.8rem;
}

.postDetail p code {
  //font-size: 0.9rem;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  background-color: var(--ah-color-black);
  padding: 0.1rem 0.1rem;
  margin: 0.0rem 0.1rem;
}

.k-card-body {
  background-color: var(--ah-color-black);
  margin: 3px 0;
}

.k-card-title {
  margin: 0 0 5px;
  color: var(--ah-color-white);
  font-weight: bold;
  //font-size: 1.3rem;
}

.k-card-subtitle {
  //font-size: 0.7rem;
  color: var(--ah-color-white)
}

.k-card {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #202020;
  //font-size: 1.0rem;
}

.k-card .k-card-body {
}

.k-card-avatar, .k-card .k-avatar {
  width: 25px;
  height: 25px;
  flex-basis: 25px;
}

.k-card-actions a {
  //font-size: 0.9rem;
}

.k-card-actions .prevPostLink {
  //width: 50%;
  float: left;
  text-Align: start;
}

.k-card-actions .nextPostLink {
  //width: 50%;
  float: right;
  text-Align: end;
}

.k-card-actions .bullseyeLink {
  width: 10%;
  text-Align: center;
}

.k-i-arrow-left {
  //font-size: 17px;
  color: #e30c17;
}

.k-i-arrow-right {
  //font-size: 17px;
  color: #e30c17;
}

.k-appbar .k-appbar-section {
  display: contents;;
  flex-wrap: nowrap;
  align-items: end;
}

.k-appbar-section .k-button {
  padding: 0;
  text-transform: lowercase;
}

.k-appbar-bottom {
  padding: 5px 0px !important;
  background-color: #000;
}

.k-appbar-section ul {
  list-style-type: none;
  //font-size: 0.8rem;
}

.appbarTop .appbar-section-top {
  display: table-column;
  text-Align: end;
}

.git-info {
  background-color: black;
  color: gray;
  //font-size: 0.8rem;
  margin: 0 0 10px;
  padding: 0 10px;
}

.top-menu {
  padding: 0 0 25px;
}

.top-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.postlike {
  text-transform: lowercase;
  color: var(--ah-color-white);
  font-weight: 700;
  background-color: #ff0e1a;
  border: none;
  padding: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

//@media only screen and (min-width: 900px) {
@media only screen and (max-width: 667px) {
  .top-title {
    font-size: 1.3rem;
  }
}
